export const titleCase = (str) => {
  if (str === undefined || str === null || str === "") {
    return str;
  } else {
    return str[0].toUpperCase() + str.substring(1);
  }
};

export const addDays = (date, t) => {
  let d = new Date(date);
  d.setDate(d.getDate() + t);
  return d;
};

export const calculateTimeLeft = (date) => {
  let difference = date - new Date();
  let timeLeft = {};
  let str = "";

  if (difference > 0) {
    timeLeft = {
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
    if (timeLeft.hours > 9) {
      str += timeLeft.hours;
    } else if (timeLeft.hours > 0) {
      str += "0" + timeLeft.hours;
    } else {
      str += "00";
    }
    str += ":";
    if (timeLeft.minutes > 9) {
      str += timeLeft.minutes;
    } else if (timeLeft.minutes > 0) {
      str += "0" + timeLeft.minutes;
    } else {
      str += "00";
    }
    str += ":";
    if (timeLeft.seconds > 9) {
      str += timeLeft.seconds;
    } else if (timeLeft.seconds > 0) {
      str += "0" + timeLeft.seconds;
    } else {
      str += "00";
    }
  }
  return str;
};

export const toImage = (data = "") => {
  if (data.length > 1) {
    return "data:image/jpg;base64," + data;
  } else {
    return null;
  }
};

export const dateTimeString = (date) => {
  if(!date) return null;
  const d = new Date(date);
  return `${d.toLocaleDateString()} ${d.toLocaleTimeString().toUpperCase()}`;
};

export const chatTime = (date) => {
  if(!date) return null;
  const d = new Date(date);
  const today = new Date();
  today.setHours(0,0,0,0)
  if(today<=d){
    return `${d.toLocaleTimeString().toUpperCase().substring(0, 5)}`;
  } else {
    return `${d.toLocaleDateString().substring(0, 5)}`;
  }
};