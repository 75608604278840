import { useEffect, useState, useContext } from "react";
import "./home.css";
import Counts from "./src/Counts";
import Testimonial from "./src/Testimonial";
import Faq from "./src/Faq";
import Contact from "./src/Contact";
import About from "./src/About";
import MenteeSection from "./src/MenteeSection";
import ErrorBoundary from "../hoc/ErrorBoundary";
import http from "../../helper/api";
import SkillCatalog from "./src/SkillCatalog";
import OurClients from "./src/OurClients";
import { UserContext } from "../../UserContext"; 


const Home = () => {

  const [titleDetails, setTitleDetails] = useState({});
  const userAuth = useContext(UserContext);


  useEffect(() => {
    http.get('public/api/getDataForHomePage/title')
      .then(response => {
        setTitleDetails(response.title);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  return (
    <>
      <section id="hero" className="d-flex align-items-center">
        <div className="container-fluid" data-aos="zoom-out" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="row">
                <div className="col-xl-8">
                  <h1 dangerouslySetInnerHTML={{ __html: titleDetails.title }}></h1>
                  <h2 dangerouslySetInnerHTML={{ __html: titleDetails.subtitle }}></h2>
                  {
                    !userAuth.verified
                      ? <a href="/signup" className="btn-get-started scrollto">Get Started</a> 
                      : <a href="/profile" className="btn-get-started scrollto">Your Profile</a>
                   }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ErrorBoundary>
        <Counts />
      </ErrorBoundary>
      
      <ErrorBoundary>
        <MenteeSection />
      </ErrorBoundary>

      <ErrorBoundary>
        <OurClients />
      </ErrorBoundary>

      <ErrorBoundary>
        <SkillCatalog />
      </ErrorBoundary>

      <ErrorBoundary>
        <Testimonial />
      </ErrorBoundary>

      <ErrorBoundary>
        <About />
      </ErrorBoundary>

      <ErrorBoundary>
        <Faq />
      </ErrorBoundary>

      <ErrorBoundary>
        <Contact />
      </ErrorBoundary>
    </>
  );
};

export default Home;