import { useEffect, useState } from "react";

import http from "../../../helper/api";

const SkillCatalog = () => {
  const [skillList, setSkillList] = useState([]);
  const [indExpList, setIndExpList] = useState([]);
  const [orgTypeList, setOrgTypeList] = useState([]);
  const [companySizeList, setCompanySizeList] = useState([]);

  useEffect(() => {
    http.get('public/api/getDataForHomePage/skillCatalog')
      .then(response => {
        // console.log(response.data);
        setSkillList(response.expertise);
        setIndExpList(response.industryExperience);
        setOrgTypeList(response.orgType);
        setCompanySizeList(response.companySize);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  return (
    <section id="skillCatalog" style={{padding: "60px 0"}}>
      <div className="container">
      <div className="row">
        <div className="col-md-4 col-lg-3">
          <div className="nav flex-column nav-pills" id="v-pills-tab-catalog" role="tablist" aria-orientation="vertical">
            <a className="nav-link active" id="v-pills-skills-tab" data-toggle="pill" href="#v-pills-skills" role="tab" aria-controls="v-pills-skills" aria-selected="true">
              Skills
            </a>
            <a className="nav-link" id="v-pills-indexp-tab" data-toggle="pill" href="#v-pills-indexp" role="tab" aria-controls="v-pills-indexp" aria-selected="false">
              Industry Experience
            </a>
            <a className="nav-link" id="v-pills-orgType-tab" data-toggle="pill" href="#v-pills-orgType" role="tab" aria-controls="v-pills-orgType" aria-selected="false">
              Organization Type
            </a>
            <a className="nav-link" id="v-pills-compSize-tab" data-toggle="pill" href="#v-pills-compSize" role="tab" aria-controls="v-pills-compSize" aria-selected="false">
              Company Size
            </a>
          </div>
        </div>
        <div className="col-md-8 col-lg-9">
          <div className="tab-content" id="v-pills-tabContent">
            <div className="tab-pane fade show active" id="v-pills-skills" role="tabpanel" aria-labelledby="v-pills-skills-tab">
              <ul>
                {skillList.map(skill => <li key={skill}>{skill}</li>)}
              </ul>
            </div>
            <div className="tab-pane fade" id="v-pills-indexp" role="tabpanel" aria-labelledby="v-pills-indexp-tab">
              <ul>
                {indExpList.map(industryExp => <li key={industryExp}>{industryExp}</li>)}
              </ul>
            </div>
            <div className="tab-pane fade" id="v-pills-orgType" role="tabpanel" aria-labelledby="v-pills-orgType-tab">
              <ul>
                {orgTypeList.map(orgType => <li key={orgType}>{orgType}</li>)}
              </ul>
            </div>
            <div className="tab-pane fade" id="v-pills-compSize" role="tabpanel" aria-labelledby="v-pills-compSize-tab">
              <ul>
                {companySizeList.map(companySize => <li key={companySize}>{companySize}</li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>
      </div>
    </section>
  )
};

export default SkillCatalog;