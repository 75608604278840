import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useFormik } from "formik";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MenuItem from "@material-ui/core/MenuItem";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import "./signup.scss";
import Loader from "../common/template/Loader";
import http from "../../helper/api";
import signupImg from "../../assets/img/blog/blog-recent-5.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1.1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const memberTypeList = ["Mentee", "Mentor"].map((e) => ({
  label: e,
  value: e.toLowerCase(),
}));
const initialValues = {
  name: "",
  email: "",
  memberType: "mentee",
  password: "",
  rpassword: "",
  flag: false,
  showPassword: false,
};

const wrapper = {
  minHeight: "90vh",
  paddingTop: "15vh",
  backgroundColor: "#f8f8f8",
};

const Signup = ({ history }) => {
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const [isLoading, setIsLoading] = useState(false);

  const registerUser = (values, { resetForm }) => {
    setIsLoading(true);
    const { showPassword, ...temp } = values;
    http
      .post("api/signup", temp)
      .then((response) => {
        if (response.success) {
          resetForm(initialValues);
          Swal.fire({
            title: "Success",
            text: `We have sent a link to ${values.email} to verify your email. Please click on this link and fill out your profile details in order to complete your account setup.`,
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "Error in registering",
            icon: "error",
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: error.response?.data.message || "Server Error",
          icon: "error",
        });
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit: registerUser,
    validate: (values) => {
      let errors = {};
      if (!values.name) {
        errors.name = "Required";
      }

      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(values.email)
      ) {
        errors.email = "Invalid email format";
      }

      if (!values.memberType) {
        errors.memberType = "Required";
      }

      if (!values.password) {
        errors.password = "Required";
      }

      if (!values.rpassword) {
        errors.rpassword = "Required";
      } else if (values.password !== values.rpassword) {
        errors.rpassword = "Password did not match";
      }
      return errors;
    },
  });

  useEffect(() => {
    switch (query.get("type")) {
      case "mentee":
        formik.setFieldValue("memberType", "mentee");
        break;
      case "mentor":
        formik.setFieldValue("memberType", "mentor");
        break;
      default:
        formik.setFieldValue("memberType", "mentee");
    }
  }, []);

  const handleClickShowPassword = () => {
    formik.setFieldValue("showPassword", !formik.values.showPassword);
  };

  return (
    <>
      <section style={wrapper}>
        <Loader open={isLoading} />
        <div className="mycontainer">
          <div className="signup-content">
            <div className="signup-form">
              <h2 className="form-title">Sign up</h2>
              <form
                className={classes.root}
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <div>
                  <TextField
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    fullWidth
                    label="Your Name"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-error">{formik.errors.name}</div>
                  )}
                </div>
                <div>
                  <TextField
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    fullWidth
                    label="Your Email"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-error">{formik.errors.email}</div>
                  )}
                </div>
                <div>
                  <TextField
                    id="select-member-type"
                    select
                    label="Member Type"
                    name="memberType"
                    value={formik.values.memberType}
                    onChange={formik.handleChange}
                    // helperText="Please select member type"
                    fullWidth
                  >
                    {memberTypeList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {formik.touched.memberType && formik.errors.memberType && (
                    <div className="text-error">{formik.errors.memberType}</div>
                  )}
                </div>
                <div>
                  <TextField
                    name="password"
                    type={formik.values.showPassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    fullWidth
                    label="Password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {formik.values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="text-error">{formik.errors.password}</div>
                  )}
                </div>
                <div>
                  <TextField
                    name="rpassword"
                    type="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rpassword}
                    fullWidth
                    label="Repeat Your Password"
                  />
                  {formik.touched.rpassword && formik.errors.rpassword && (
                    <div className="text-error">{formik.errors.rpassword}</div>
                  )}
                </div>
                <div
                  style={{
                    position: "relative",
                    margin: "1rem 0",
                    display: "inline-flex",
                  }}
                >
                  <Checkbox
                    name="flag"
                    checked={formik.values.flag}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    color="primary"
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                  />
                  <div style={{ fontSize: "0.8rem" }}>
                    <span>I agree to the </span>
                    <span
                      className="link"
                      data-toggle="modal"
                      data-target="#termnconditionModal"
                    >
                      Terms and Conditions
                    </span>
                    <span> and </span>
                    <span
                      className="link"
                      data-toggle="modal"
                      data-target="#privacyPolicyModal"
                    >
                      Privacy policy.
                    </span>
                  </div>
                </div>
                <button type="submit" className="btn-one">
                  Register
                </button>
                <div
                  onClick={() => history.push("signin")}
                  className="signup-link"
                >
                  I am already a member
                </div>
              </form>
            </div>
            <div className="signup-image">
              <figure>
                <img src={signupImg} alt="sign up" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      {/* Terms and Conditions Modal */}
      <div
        className="modal fade"
        id="termnconditionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="termnconditionModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Mentor On Demand™ Terms and Conditions
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body term-n-condition">
              <strong>Conditions of use</strong>
              <div>
                By using this website, you the User (“User”) certify that you
                have read and reviewed this Agreement and that you agree to
                comply with its terms. If you do not want to be bound by the
                terms of this Agreement, you are advised to leave the website
                accordingly. Accelerator Group LLC. and it&#39;s Initiative -
                MentorOnDemand™(together, “MentorOnDemand”) only grants use and
                access of this website, its products, and its services to those
                who have accepted its terms.
              </div>
              <strong>Participation Agreement</strong>
              <div>
                By accepting this Agreement, you certify that you are accepting
                the terms of “Participation Agreement” noted subsequently.
              </div>
              <strong>Privacy policy</strong>
              <div>
                Before you continue using our website, we advise you to read our
                “Privacy Policy” regarding our user data collection. It will
                help you better understand our practices.
              </div>
              <strong>Age restriction</strong>
              <div>
                You must be at least 18 (eighteen) years of age before you can
                use this website. By using this website, you warrant that you
                are at least 18 years of age and you will legally adhere to this
                Agreement. MentorOnDemand™ assumes no responsibility for
                liabilities related to age misrepresentation.
              </div>
              <strong>Intellectual property</strong>
              <div>
                You agree that all materials, products, and services provided on
                this website are the property of MentorOnDemand™and Accelerator
                Group LLC., its affiliates, directors, officers, employees,
                agents, suppliers, or licensors including all copyrights, trade
                secrets, trademarks, patents, and other intellectual property.
                You also agree that you will not reproduce or redistribute the
                Mentor OnDemand™’s and Accelerator Group LLC.’s intellectual
                property in any way, including electronic, digital, or new
                trademark registrations.
              </div>
              <div>
                You grant MentorOnDemand™ a royalty-free and non-exclusive
                license to display, use, copy, transmit, and broadcast the
                content you upload and publish. For issues regarding
                intellectual property claims, you should contact the company in
                order to come to an agreement.
              </div>
              <strong>User accounts</strong>

              <div>
                As a user of this website, you may be asked to register with us
                and provide private information. You are responsible for
                ensuring the accuracy of this information, and you are
                responsible for maintaining the safety and security of your
                identifying information. You are also responsible for all
                activities that occur under your account or password.
              </div>
              <div>
                If you think there are any possible issues regarding the
                security of your account on the website, inform us immediately
                so we may address them accordingly.
              </div>
              <div>
                We reserve all rights to terminate accounts, edit or remove
                content and cancel orders at our sole discretion.
              </div>
              <strong>Applicable law</strong>
              <div>
                By visiting this website, you agree that the laws of the State
                of Florida, United States of America, without regard to
                principles of conflict laws, will govern these terms and
                conditions, or any dispute of any sort that might come between
                MentorOnDemand™, Accelerator Group and you, or its business
                partners and associates.
              </div>
              <strong>Disputes</strong>
              <div>
                Any dispute related in any way to your visit to this website or
                to products you purchase from us shall be arbitrated by state or
                federal court of our choosing and you consent to exclusive
                jurisdiction and venue of such courts.
              </div>
              <strong>Indemnification</strong>
              <div>
                You agree to indemnify MentorOnDemandMentorOnDemand™ and its
                affiliates and hold MentorOnDemand™ harmless against legal
                claims and demands that may arise from your use or misuse of our
                services. We reserve the right to select our own legal counsel.
              </div>
              <strong>Limitation on liability</strong>
              <div>
                MentorOnDemand™is not liable for any damages that may occur to
                you as a result of your misuse of our website.
              </div>
              <div>
                MentorOnDemand™reserves the right to edit, modify, and change
                this Agreement at any time. We shall let our users know of these
                changes through electronic mail. This Agreement is an
                understanding between MentorOnDemand™and the user, and this
                supersedes and replaces all prior agreements regarding the use
                of this website.
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Privacy Policy Modal */}
      <div
        className="modal fade"
        id="privacyPolicyModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="privacyPolicyModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="privacyPolicyModalTitle">
                MentorOnDemand™ Privacy Policy
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body term-n-condition">
              <strong>Information that is gathered from visitors</strong>
              <div>
                In common with other websites, log files are stored on the web
                server saving details such as the visitor&#39;s IP address,
                browser type, referring page and time of visit.
              </div>
              <div>
                Cookies may be used to remember visitor preferences when
                interacting with the website.
              </div>
              <div>
                Where registration is required, the visitor&#39;s email and a
                username will be stored on the server.
              </div>
              <strong>How the Information is used</strong>

              <div>
                The information is used to enhance the visitor&#39;s experience
                when using the website to display personalised content and
                possibly advertising.
              </div>
              <div>
                E-mail addresses will not be sold, rented or leased to 3rd
                parties.
              </div>
              <div>
                E-mail may be sent to inform you of news of our services or
                offers by us or our affiliates.
              </div>
              <strong>Visitor Options</strong>
              <div>
                If you have subscribed to one of our services, you may
                unsubscribe by following the instructions which are included in
                email that you receive.
              </div>
              <div>
                You may be able to block cookies via your browser settings but
                this may prevent you from access to certain features of the
                website.
              </div>
              <strong>Cookies</strong>

              <div>
                Cookies are small digital signature files that are stored by
                your web browser that allow your preferences to be recorded when
                visiting the website. Also they may be used to track your return
                visits to the website.
              </div>
              <div>
                3rd party advertising companies may also use cookies for
                tracking purposes.
              </div>
              <strong>Google Ads</strong>
              <div>
                Google, as a third party vendor, uses cookies to serve ads.
              </div>
              <div>
                Google&#39;s use of the DART cookie enables it to serve ads to
                visitors based on their visit to sites they visit on the
                Internet.
              </div>
              <div>
                Website visitors may opt out of the use of the DART cookie by
                visiting the Google ad and content network privacy policy.
              </div>

              <strong className="fs-sm">PARTICIPATION AGREEMENT</strong>
              <br />
              <strong>Between Accelerator Group LLC. and User</strong>
              <div>
                This RELEASE AND PARTICIPATION AGREEMENT (this “Release”) is
                executed as of the date of registering a User account by User on
                MentorOnDemand’s website in favor of Accelerator Group LLC.
                organized and existing under the laws of the State of Michigan,
                and its Strategic Partners (“Strategic Partners”) , members,
                directors, officers, employees, mentors and agents (hereinafter
                sometimes collectively referred to as the “Organization”).
              </div>
              <div>
                User desires to participate in the Organization’s Tech Platform
                based Mentoring (the “Program”). In connection with the Program,
                User will be required to engage in certain activities (related
                to being a User) (“Activities”). User understands that the
                Program is voluntary in nature and User shall not receive
                compensation or remuneration for any of his/her time expended in
                connection with the Program.
              </div>
              <div>
                In exchange for being allowed to participate in the Program and
                Activities as a User and for other good and valuable
                consideration, the receipt and sufficiency of which User
                acknowledges, User hereby freely, voluntarily, and without
                duress executes this Release and further agree to the following
                terms:
              </div>
              <ol>
                <li>
                  <strong>User’s Representation and Warranties.</strong>
                  <ol type="a">
                    <li><span>
                      User understands and agrees that any advice received
                      during the Program is solely for the purpose of enhancing
                      User’s knowledge base regarding business processes and for
                      obtaining related guidance.</span>
                    </li>
                    <li><span>
                      Any statements, advice or comments provided by Mentors<sup>1 </sup>
                      during the Program shall not constitute legal, financial
                      or accounting advice and shall further not be deemed to be
                      mandatory advice. In that regard, User understands and
                      agrees that User shall not rely upon any Mentor’s
                      statements, advice or comments as legal, financial or
                      accounting advice.</span>
                    </li>
                    <li><span>
                      User understands and agrees that User shall not rely
                      upon a Mentor’s statements, advice or comments as a
                      substitute for User’s own independent judgment. User
                      agrees that any decisions to act in relation to User’s own
                      business shall not be attributable to the Organization or
                      the individual Mentor.</span>
                    </li>
                    <li><span>
                      User further understands and agrees that the Mentor
                      does not and cannot offer any advice about specific
                      business, legal, financial or tax decisions.</span>
                    </li>
                    <li><span>
                      User also agrees that User shall not solicit or seek
                      financial assistance from any Mentor.</span>
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>No Expectation of Confidentiality.</strong>
                  <div>
                    User agrees that Organization and Mentors are not bound by a
                    duty of confidentiality with respect to any disclosures made
                    by User. Neither the Organization, nor the Mentors, shall be
                    required to keep any information, documents, or ideas
                    disclosed by the Users as confidential.
                  </div>
                </li>
                <li>
                  <strong>Non-Disparagement.</strong>
                  <div>
                    User agrees that, unless required to do so by legal process,
                    User shall not at any time make, publish, or communicate to
                    any person or entity or in any public forum (including
                    without limitation any online forums), any defamatory,
                    maliciously false, or disparaging remarks, comments,
                    representations, or statements concerning the Organization
                    or the Mentors, or any of the Organization’s employees,
                    officers, directors now or in the future. For purposes of
                    this paragraph, a disparaging statement or representation is
                    any communication which, if publicized to another, would
                    cause or tend to cause the recipient of the communication to
                    question the business condition, integrity, competence, good
                    character, or product quality of the person or entity to
                    whom the communication relates.
                  </div>
                </li>
                <li>
                  <strong>Photographic Release.</strong>
                  <div>
                    User understands and agrees that during the course of the
                    Program and related Activities, User may be photographed
                    and/or videotaped by the Organization for internal and/or
                    promotional use. User hereby grants and conveys to the
                    Organization all right, title, and interest, including but
                    not limited to, any royalties, proceeds, or other benefits,
                    in any and all such photographs or recordings, and consent
                    to the Organization&#39;s use of User’s name, image,
                    likeness, and voice in perpetuity, in any medium or format,
                    for any publicity without further compensation or
                    permission.
                  </div>
                </li>
                <li>
                  <strong>
                    Acknowledgement of Accelerator Group’s ownership.
                  </strong>
                  <div>
                    User understand that all materials shared with the User,
                    including but not limited to the written and oral content
                    shared; all represent the intellectual property of
                    Accelerator Group and its Strategic Partners and as such are
                    valuable and confidential assets of the Accelerator Group
                    and Organization. This material may not be reproduced,
                    copied or shared with anyone, in any form, without the
                    explicit written permission of AWE Foundation’s and
                    Strategic Partner’s duly authorized representative.
                  </div>
                </li>
                <li>
                  <strong>Release and Waiver.</strong>
                  <div>
                    User hereby irrevocably and unconditionally, fully and
                    forever waives, releases, and discharges the Organization,
                    including the Organization’s parents, subsidiaries,
                    affiliates, predecessors, successors, and assigns, and each
                    of their respective officers, directors, employees, in their
                    corporate and individual capacities (“Released Parties”)
                    from any and all claims, demands, actions, causes of
                    actions, judgments, rights, fees, damages, debts,
                    obligations, liabilities, and expenses (inclusive of
                    attorneys’ fees) of any kind whatsoever, whether known or
                    unknown, that User may have or has ever had against the
                    Released Parties, or any of them, arising out of User’s
                    participation in the Program and related Activities.
                  </div>
                </li>
                <li>
                  <strong>Indemnification.</strong>
                  <div>
                    User hereby agrees to indemnify, defend, and hold harmless
                    the Organization from any and all liability, losses,
                    damages, judgments, or expenses, including attorneys&#39;
                    fees, that it may incur or sustain as a result of User’s
                    negligence or willful misconduct in connection with User’s
                    participation in the Program and related Activities, arising
                    out of any third-party claim.
                  </div>
                </li>
                <li>
                  <strong>Miscellaneous.</strong>
                  <div>
                    User hereby agrees that this Release represents the full
                    understanding between the Organization and User and
                    supersedes all other prior agreements, understandings,
                    representations, and warranties, both written and oral,
                    between User and Organization, with respect to the subject
                    matter hereof. If any term or provision of this Release
                    shall be held to be invalid by any court of competent
                    jurisdiction, that term or provision shall be deemed
                    modified so as to be valid and enforceable to the full
                    extent permitted. The invalidity of any such term or
                    provision shall not otherwise affect the validity or
                    enforceability of the remaining terms and provisions. This
                    Release is binding on and inures to the benefit of the
                    Organization and its respective heirs, executors,
                    administrators, legal representatives, successors, and
                    permitted assigns. Section headings are for convenience of
                    reference only and shall not define, modify, expand, or
                    limit any of the terms of this Release.
                  </div>
                </li>
                <li>
                  <strong>Relief.</strong>
                  <div>
                    User acknowledges and agrees that due to the unique nature
                    of the information provided to her under this Agreement, any
                    breach of any clause may result in irreparable harm to
                    Accelerator Group, it’s Strategic Partners and Organization,
                    and therefore, that upon any such breach or any threat
                    thereof, AWE shall be entitled to appropriate equitable
                    relief in addition to what other remedies it might have at
                    law.
                  </div>
                </li>
                <li>
                  <strong>Governing Law.</strong>
                  <div>
                    User hereby agrees that this Release is intended to be as
                    broad and inclusive as permitted, and that this Release
                    shall be governed by and interpreted in accordance with the
                    laws of the State of Florida, without reference to any
                    choice of law doctrine. Exclusive venue for any claims
                    arising out of or related to this Release shall be the state
                    courts of Miami-Dade County, Florida.
                    </div>
                    <em>
                    By Clicking “I AGREE”
                    in the reference User Participation Form, User acknowledges
                    that User has read and understood all the terms of this
                    Release and Participation Agreement.
                  </em>
                </li>
              </ol>
                      <div style={{borderTop:"1px solid grey"}}><sup>1 </sup>
                       The term “Mentor” or “Mentors” shall refer to the individuals who may or may not be members of the
Organization and who have volunteered to be mentors for the specific Program of the Organization, as
identified and defined herein.
</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
