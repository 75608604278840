import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Swal from 'sweetalert2';
import http from "../../../helper/api";

const initialValues = {
  name: "",
  email: "",
  subject: "",
  message: ""
};

const dataMissingMsg = "NA";

const Contact = () => {

  const [contactDetails, setContactDetails] = useState({});

  useEffect(() => {
    http.get('public/api/getDataForHomePage/contactDetails')
      .then(response => {
        setContactDetails(response.contactDetails);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  const sentMessage = (values, {resetForm}) => {
    http.post('public/api/postFeedback', values)
    .then(response => {
      Swal.fire({
        title: 'Success',
        text: "Message submitted",
        icon: 'success',
      })
      resetForm(initialValues);
    })
    .catch(error => {
      Swal.fire({
        title: 'Error',
        text: error.response?.data.message,
        icon: 'error',
      })
    })
  };

  const contactForm = useFormik({
    initialValues,
    onSubmit: sentMessage,
    validate: values => {
      let errors = {};
      if(!values.name){
        errors.name = "Required"
      }
      if(!values.email){
        errors.email = "Required"
      }
      if(!values.subject){
        errors.subject = "Required"
      }
      if(!values.message){
        errors.message = "Required"
      }
      return errors;
    }
  });

  return (
    <section id="contact" className="contact" style={{padding:"60px 0 30px 0"}}>
      <div className="container" data-aos="fade-up">

        <div className="section-title">
          <h2>Contact</h2>
          <p></p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">

          <div className="col-lg-6 mb-5">

            <div className="row">
              <div className="col-md-12">
                <div className="info-box">
                  <i className="fas fa-map-marker-alt"></i>
                  <h3>Our Address</h3>
                  <p>{contactDetails.address || dataMissingMsg}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <h3>Email Us</h3>
                  <p>{contactDetails.email || dataMissingMsg}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4">
                  <i className="fas fa-phone-alt"></i>
                  <h3>Call Us</h3>
                  <p>{contactDetails.contactNo || dataMissingMsg}</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-6 mb-5">
            <form  onSubmit={contactForm.handleSubmit} autoComplete="off" className="email-form">
              <div className="form-row">
                <div className="col form-group">
                  <input 
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                    value={contactForm.values.name}
                  />
                {contactForm.touched.name && contactForm.errors.name && <div className="text-error">{contactForm.errors.name}</div>}
                </div>
                <div className="col form-group">
                  <input 
                    type="email" 
                    className="form-control" 
                    name="email" 
                    id="email"
                    placeholder="Your Email"
                    onChange={contactForm.handleChange}
                    onBlur={contactForm.handleBlur}
                    value={contactForm.values.email}
                  />
                {contactForm.touched.email && contactForm.errors.email && <div className="text-error">{contactForm.errors.email}</div>}
                </div>
              </div>
              <div className="form-group">
                <input 
                  type="text" 
                  className="form-control" 
                  name="subject"
                  placeholder="Subject"
                  onChange={contactForm.handleChange}
                  onBlur={contactForm.handleBlur}
                  value={contactForm.values.subject}
                />
                {contactForm.touched.subject && contactForm.errors.subject && <div className="text-error">{contactForm.errors.subject}</div>}
              </div>
              <div className="form-group">
                <textarea 
                  className="form-control" 
                  name="message" 
                  rows="5" 
                  placeholder="Message"
                  onChange={contactForm.handleChange}
                  onBlur={contactForm.handleBlur}
                  value={contactForm.values.message}
                />
                {contactForm.touched.message && contactForm.errors.message && <div className="text-error">{contactForm.errors.message}</div>}
              </div>
              {/* <div className="mb-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div> */}
              <div className="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Contact;