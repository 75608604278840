import { useEffect, useState } from "react";
import http from "../../../helper/api";
import { toImage } from "../../../helper/util";

const OurClients = () => {
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    http.get('public/api/client')
      .then(response => {
        setClientList(response);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  return (
    <section id="our-clients" style={{ padding: "60px 0" }}>
      <div className="container">
        <div className="section-title text-center">
          <h2>Our Partners</h2>
        </div>
        <div className="d-flex flex-row align-self-stretch justify-content-around flex-wrap">
          {clientList.map((client) => (
            <div style={{margin:"auto"}} key={client._id}>
              <div className="mx-2 my-4">
                <img
                  src={toImage(client.image)}
                  alt={client.name}
                  style={{height:"auto", width:"220px"}}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurClients;
