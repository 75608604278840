import { Link, useLocation } from "react-router-dom";
import { titleCase } from "../../../helper/util";
import "./header.css";

const Header = () => {
  const location = useLocation();

  if (
    ["home", "signin", "signup", "resetPassword", "verifyEmail", "payment-success", "payment-error", "chat"].includes(
      location.pathname.split("/")[1]
    )
  ) {
    return null;
  } else {
    const breadcrumb = location.pathname
      .substring(1)
      .split("/")
      .map((e) => e.split(/(?=[A-Z])/).join(" "));
    return (
      <section className="breadcrumbs mb-3">
        <div className="container">
          <ol>
            <li>
              <Link to="/home">Home</Link>
            </li>
            {breadcrumb.map((e, i) => (
              <li key={e}>
                <Link to={"/" + breadcrumb.slice(0, i + 1).join("/")}>
                  {titleCase(e)}
                </Link>
              </li>
            ))}
          </ol>
          <h2 className="text-white">
            {breadcrumb[0] !== "feedback"
              ? titleCase(breadcrumb[breadcrumb.length - 1])
              : "Meeting Feedback"}
          </h2>
        </div>
      </section>
    );
  }
};
export default Header;
