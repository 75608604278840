import { useEffect, useState } from "react";
import Chip from '@material-ui/core/Chip';

import EditProfile from "./EditProfile";
import dummyImg from "../../assets/img/team/female-dummy-user.png";
import http from "../../helper/api";
import { toImage } from "../../helper/util";
import Loader from "../common/template/Loader";
import "./profile.scss";

import { formatPhoneNumberIntl } from 'react-phone-number-input'
// formatPhoneNumberIntl('+12133734253') === '+1 213 373 4253'

const Profile = ({ userid, isOther }) => {
  const [profileData, setProfileData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isEdit && !isOther) {
      setIsLoading(true);
      http.get('api/getProfile')
        .then(response => {
          setProfileData(response);
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
          setIsLoading(false);
        })
    }
  }, [isEdit, isOther]);
  useEffect(() => {
    if (isOther && userid) {
      setIsLoading(true);
      http.get('api/getUserProfile/' + userid)
        .then(response => {
          setProfileData(response);
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
          setIsLoading(false);
        })
    }
  }, [isOther, userid]);

  const viewProfilePage = () => {
    setIsEdit(false);
  }

  if (isEdit) {
    return <EditProfile viewProfilePage={viewProfilePage} />;
  } else {

    return (
      <section>
        <div className="container" data-aos="fade-up">
          <Loader open={isLoading} />
          <div className="row">
            <div className="col-lg-3 col-sm-5 col-12">
              <div className="profile_img">
                <div className="aos-init aos-animate w-100">
                  <img src={toImage(profileData.image) || dummyImg} alt="Avatar"
                    title="Change the avatar" />
                </div>
              </div>
              <h4>{profileData.name}</h4>

              <ul className="list-unstyled user_data">
                <li><i className="fa fa-map-marker user-profile-icon"></i>&nbsp;
                  {profileData?.location?.city ? `${profileData?.location?.city}, ${profileData?.location?.state}, ${profileData?.location?.country}` : "NA"}
                </li>

                <li>
                  <i className="fa fa-briefcase user-profile-icon"></i>&nbsp;
                  {profileData.jobRole || "NA"}
                </li>
                <li>
                  <i className="fas fa-building user-profile-icon"></i>&nbsp;
                  {profileData.companyName || "NA"}
                </li>
              </ul>
              {!isOther && (
                <>
                  <button type="button" onClick={() => { setIsEdit(true) }} className="btn-one">
                    <i className="fa fa-edit m-right-xs"></i>Edit Profile
                  </button>
                  <br />
                </>
              )}
              <h5 className="mt-4">Contact Details</h5>
              <ul className="list-unstyled user_data">
                <li>
                  <h6>Email</h6>
                  <p>
                    <i className="fa fa-envelope"></i>&nbsp;
                    {profileData.email}
                  </p>
                </li>
                <li>
                  <h6>Phone</h6>
                  <p>
                    <i className="fa fa-phone-alt"></i>&nbsp;
                    {formatPhoneNumberIntl(profileData.contactNo) || "NA"}
                  </p>
                </li>
                <li>
                  <h6>LinkedIn</h6>
                  <p>
                    <i className="fab fa-linkedin"></i>&nbsp;
                    {profileData.linkedinUrl || "NA"}
                  </p>
                </li>
                <li>
                  <h6>Availabilty Timeframe</h6>
                  <p>
                    {profileData.availabilityTimeframe || "NA"}
                  </p>
                </li>
                <li>
                  <h6>Meeting URL</h6>
                  <p>
                    {profileData.meetingWebUrl || "NA"}
                  </p>
                </li>

              </ul>
            </div>

            <div className="col-lg-9 col-sm-7 col-12">

              <div className="profile_title mb-5">
                <div>
                  <h5>About</h5>
                  <p style={{ minHeight: "50px" }}>{profileData.about || "Details Not Updated."}</p>
                </div>
              </div>

              <div className="mb-5">
                <div>
                  <h5>Video Introduction</h5>
                  <p style={{ minHeight: "50px" }}>{profileData.videoLinkUrl || "Details Not Updated."}</p>
                </div>
              </div>

              <div className="row skills-content">
                <div className="col-lg-6 aos-init aos-animate mb-5" data-aos="fade-up">
                  <h5>Skills</h5>
                  <ul className="list-unstyled user_data">

                    {profileData.skills && profileData.skills.length > 0 ? profileData.skills.map(s => (
                      <li key={s} className="list-group-item d-flex justify-content-between align-items-center">
                        <span>{s}</span>
                        <i className="fab fa-black-tie"></i>
                      </li>
                    )) : <span className="ml-3">NA</span>}

                  </ul>
                </div>

                <div className="col-lg-6 aos-init aos-animate mb-5" data-aos="fade-up" data-aos-delay="100">
                  <h5>Industry Experience</h5>

                  <ul className="list-unstyled user_data">

                    {profileData.industryExperience && profileData.industryExperience.length > 0
                      ? profileData.industryExperience.map(s => (
                        <li key={s} className="list-group-item d-flex justify-content-between align-items-center">
                          <span>{s}</span>
                          <i className="fa fa-building"></i>
                        </li>
                      )) : <span className="ml-3">NA</span>}
                  </ul>
                </div>

              </div>

              <div className="row skills-content">
                <div className="col-lg-6 aos-init aos-animate mb-5" data-aos="fade-up">
                  <h5>Languages</h5>
                  <div className="row skills-content ml-1">
                    {profileData.language && profileData.language.length > 0
                      ? profileData.language.map(s => (
                        <Chip className="ml-3" key={s} label={s} color="primary" />
                      )) : <span className="ml-3">NA</span>}
                  </div>
                </div>

                <div className="col-lg-6 aos-init aos-animate mb-5" data-aos="fade-up">
                  <h5>Method Of Contact</h5>
                  <div className="row skills-content ml-1">
                    {profileData.methodOfContact && profileData.methodOfContact.length > 0
                      ? profileData.methodOfContact.map(s => (
                        <Chip className="ml-3" key={s} label={s} color="primary" />
                      )) : <span className="ml-3">NA</span>}
                  </div>
                </div>
              </div>

              <div className="row skills-content">
                <div className="col-lg-6 aos-init aos-animate mb-5" data-aos="fade-up">
                  <h5>Organization Type</h5>
                  <div className="row skills-content ml-1">
                    {profileData.orgType ? profileData.orgType : <span className="ml-3">NA</span>}
                  </div>
                </div>
                <div className="col-lg-6 aos-init aos-animate mb-5" data-aos="fade-up">
                  <h5>Company Size</h5>
                  <div className="row skills-content ml-1">
                    {profileData.companySize ? profileData.companySize : <span className="ml-3">NA</span>}
                  </div>
                </div>
              </div>

            </div>
          </div>

        </div >
      </section >
    );
  }
};

export default Profile;