export const validateMeetingUrl = (url = "") => {
  if (
    !(
      url.startsWith("https://meet.google.com/") ||
      url.startsWith("https://teams.microsoft.com/l/meetup-join/") ||
      url.includes(".webex.com/meet/") ||
      url.includes(".zoom.us/")
    )
  ) {
    throw new Error("Invalid URL");
  }
};
