import { useEffect, useState } from "react";
// import $ from "jquery";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import dummyUser from "../../../assets/img/testimonials/female-dummy-user.png";
import { toImage } from "../../../helper/util";
import http from "../../../helper/api";

const Testimonial = () => {
  const [testimonialList, setTestimonialList] = useState([]);

  useEffect(() => {
    http.get('public/api/getDataForHomePage/testimonial')
      .then(response => {
        // console.log("Home:",response)
        setTestimonialList(response.testimonial);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  return (
    <section
      id="testimonials"
      className="testimonials"
      style={{ padding: "60px 0" }}
    >
      <div className="container" data-aos="fade-up">
        <div className="section-title text-center">
          <h2>Testimonials</h2>
        </div>
      </div>

      <div className="container">
        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="col-xl-10">
            <Carousel autoPlay infiniteLoop>
              {testimonialList.map((testimonial, i) => (
                <div key={i} className="testimonial-wrap">
                  <div className="testimonial-item">
                    <div style={{ width: "30%", display: "inline-block" }}>
                      {/* <img src={dummyUser} className="testimonial-img" alt="" /> */}
                      <img className="testimonial-img" src={toImage(testimonial.image) || dummyUser} alt="Avatar"
                    title="Change the avatar" />
                    </div>

                    <div style={{ width: "70%", display: "inline-block" }}>
                      <p>
                        <i className="fa fa-quote-left quote-icon-left"></i>
                        {testimonial.comment}
                        <i className="fa fa-quote-right quote-icon-right"></i>
                      </p>
                      <h3>{testimonial.name}</h3>
                      <h4>{testimonial.companyName}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
