import { useEffect, useState } from "react";
import http from "../../../helper/api";

const Counts = () => {
  const [sessionMap, setSessionMap] = useState({});

  useEffect(() => {
    http.get('public/api/sessionCount')
      .then(response => {
        setSessionMap(response);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);


  return (
    <section id="counts" className="counts" style={{padding:"100px 0 40px 0"}}>
      <div className="container" data-aos="fade-up">

        <div className="row">

          <div className="col-lg-3 col-md-4">
            <div className="count-box">
              <i className="fas fa-user-tie"></i>
              <span data-toggle="counter-up">{sessionMap.mentor}</span>
              <p>Leaders offering their time</p>
            </div>
          </div>

          <div className="col-lg-3 offset-lg-1 col-md-4 mt-5 mt-md-0">
            <div className="count-box">
            <i className="fa fa-user" aria-hidden="true"></i>
              <span data-toggle="counter-up">{sessionMap.mentee}</span>
              <p>Mentees</p>
            </div>
          </div>

          <div className="col-lg-3 offset-lg-1  col-md-4 mt-5 mt-md-0">
            <div className="count-box">
              <i className="fa fa-users" aria-hidden="true"></i>
              <span data-toggle="counter-up">{sessionMap.session}</span>
              <p>Total Sessions</p>
            </div>
          </div>

        </div>

      </div>
    </section>

  );
};

export default Counts;