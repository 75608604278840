import menteeImg from "../../../assets/img/new/for_mentees.JPG";
import mentorImg from "../../../assets/img/new/for_mentors_pic.png";
import { useContext } from "react";
import { UserContext } from "../../../UserContext"; 

const MenteeSection = () => {
  const userAuth = useContext(UserContext);

  return (
    <section style={{ padding: "60px 0" }}>
      <div className="container">
        <div className="row mb-5">
          <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0">
            <h3 className="mentee-title">For Mentees</h3>
            {
             !userAuth.verified ? 
             <p>Get matched with a mentor who is an expert at exactly what you want to learn</p>
             :
            <p></p>
           }
            {

                !userAuth.verified ?
                    <a href="/signup?type=mentee" className="about-btn"><span>Register</span>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </a>

                    : <p> After your first meeting, submit your action plan form <a href = "https://bit.ly/35ekYXp"> here. </a> 
                    Submit your progress tracker form <a href="https://bit.ly/3otAiGN"> here </a> after each mentoring session.
                    After completing the mentorship program, you can fill out <a href = "https://bit.ly/3BGhARi"> this </a> completion form! </p>
            }
          </div>
          <div className="col-lg-6 order-1 order-lg-2 text-center">
            <img src={menteeImg} alt="" className="img-fluid" />
          </div>
        </div>
        
        <div className="row">
          <div className="col-lg-6 order-1 order-lg-2 mt-3 mt-lg-0 pl-5">
            <h3 className="mentee-title">For Mentors</h3>
           {
             !userAuth.verified ? 
            <p>Give back by sharing your expertise</p>
            :
            <p></p>
           }
            {
                !userAuth.verified ?
                    <a href="/signup?type=mentor" className="about-btn"><span>Register</span>
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                    : <p> After your first meeting, submit your action plan form <a href = "https://bit.ly/35ekYXp"> here. </a>
                    You can submit your progress tracker form <a href ="https://bit.ly/3lDKudU"> here </a> after each mentoring session!</p>
            }
          </div>
          <div className="col-lg-6 order-2 order-lg-1 text-center">
            <img src={mentorImg} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MenteeSection;
