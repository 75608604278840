import { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from 'react-select';
import Swal from 'sweetalert2';
import Jimp from 'jimp';

import http from "../../helper/api";
import { titleCase, toImage } from "../../helper/util";
import Loader from "../common/template/Loader";
import dummyImg from "../../assets/img/team/female-dummy-user.png";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import "./profile.scss";
import { validateMeetingUrl } from "../../helper/validator";

const memberTypeList = ["Mentee", "Mentor"].map(e => ({ label: e, value: e }));

const initialValues = {
  name: "",
  memberType: "",
  contactNo: "",
  companyName: "",
  about: "",
  meetingWebUrl: "",
  orgType: "",
  companySize: "",
  availabilityTimeframe: "",
  timezone: "",
  linkedinUrl: "",
  videoLinkUrl: "",
  language: [],
  jobRole: "",
  skills: [],
  industryExperience: [],
  methodOfContact: [],
  image: "",
  city: "",
  state:"",
  country: ""
};

const EditProfile = ({ viewProfilePage }) => {

  const [cityList, setCityList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [skillList, setSkillList] = useState([]);
  const [languageList, setLanguageList] = useState([]);
  const [indExpList, setIndExpList] = useState([]);
  const [orgTypeList, setOrgTypeList] = useState([]);
  const [timeframeList, setTimeFrameList] = useState([]);
  const [companySizeList, setCompanySizeList] = useState([]);
  const [jobRoleList, setJobRoleList] = useState([]);
  const [mocList, setMOCList] = useState([]);
  const [helptext, setHelptext] = useState({});

  const [profileData, setProfileData] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const updateUser = (values, { resetForm }) => {
    setIsLoading(true);
    http.put('api/updateProfile', values)
      .then(response => {
        // resetForm(profileData);
        if (response.success) {
          Swal.fire({
            title: 'Success',
            text: response.message,
            icon: 'success',
          })
          viewProfilePage();
        } else {
          Swal.fire({
            title: 'Error',
            text: response.message,
            icon: 'error',
          })
        }
        setIsLoading(false);
      })
      .catch(error => {
        Swal.fire({
          title: 'Error',
          text: error.response?.data.message,
          icon: 'error',
        })
        setIsLoading(false);
      })
  }

  const formik = useFormik({
    initialValues: profileData,
    enableReinitialize: true,
    onSubmit: updateUser,
    validate: values => {
      let errors = {};
      // name validation
      if (!values.name) {
        errors.name = "Required"
      }
      // memberType validation
      if (!values.memberType) {
        errors.memberType = "Required"
      }else if(values.memberType === 'Mentee') {
        if(!values.contactNo) {
          errors.contactNo = "Required"
        }
      }else if(values.memberType === 'Mentor') {
        if(!values.linkedinUrl){
          errors.linkedinUrl = "Required"
        }
      }
      // linkedinUrl validation
      if(values.linkedinUrl) {
        if(values.linkedinUrl.startsWith('https://www.linkedin.com/')){
          formik.setFieldValue("linkedinUrl", values.linkedinUrl.split('https://www.')[1])
        } else if(!values.linkedinUrl.startsWith('linkedin.com/')){
          errors.linkedinUrl = "Linkedin url should start with linkedin.com/"
        }
      }
      // about validation
      if(values.about){
        if(values.about.length > 200) {
          errors.about = `About should not exceed 200 characters limit. Remove ${values.about.length - 200} characters.`;
        }
      }
      // meetingWebUrl validation
      if(values.meetingWebUrl) {
        try {
          validateMeetingUrl(values.meetingWebUrl);
        } catch (err) {
          errors.meetingWebUrl = err.message || "Invalid URL";
        }
      }
      // videoLinkUrl validation
      if(values.videoLinkUrl) {
        if(values.videoLinkUrl.startsWith('https://www.')){
          formik.setFieldValue("videoLinkUrl", values.videoLinkUrl.split('https://www.')[1])
        }
      }
      return errors;
    }
  });

  useEffect(() => {
    http.get('api/getProfile')
      .then(response => {
        let temp = {
          name: response.name || "",
          email: response.email || "",
          companyName: response.companyName || "",
          memberType: titleCase(response.memberType) || "",
          contactNo: response.contactNo || "",
          about: response.about || "",
          meetingWebUrl: response.meetingWebUrl || "",
          orgType: response.orgType || "",
          companySize: response.companySize || "",
          availabilityTimeframe: response.availabilityTimeframe || "",
          timezone: response.timezone || "",
          linkedinUrl: response.linkedinUrl || "",
          videoLink: response.videoLink || "",
          language: response.language || [],
          jobRole: response.jobRole || "",
          skills: response.skills || [],
          industryExperience: response.industryExperience || [],
          methodOfContact: response.methodOfContact || [],
          image: response.image || "",
          city: response.location?.city || "",
          state: response.location?.state || "",
          country: response.location?.country || "",
        }
        setProfileData(temp);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);

  useEffect(() => {
    setIsLoading(true);
    http.get('api/static/getDataForProfile')
      .then(response => {
        // console.log(response.data);
        setSkillList(response.expertise.map(e => ({ label: e, value: e })));
        setIndExpList(response.industryExperience.map(e => ({ label: e, value: e })));
        setLanguageList(response.languages.map(e => ({ label: e, value: e })));
        setMOCList(response.methodOfContact.map(e => ({ label: e, value: e })));
        setOrgTypeList(response.orgType.map(e => ({ label: e, value: e })));
        setTimeFrameList(response.availabilityTimeframe.map(e => ({ label: e, value: e })));
        setCompanySizeList(response.companySize.map(e => ({ label: e, value: e })));
        setJobRoleList(response.jobRole.map(e => ({ label: e, value: e })));
        setHelptext(response.profileHelpText)
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
        setIsLoading(false);
      })
  }, []);

  useEffect(() => {
    http.get("api/static/getAllCountry").then(response => {
      setCountryList(response.map((e) => ({ label: e.name, value: e.name, id: e._id })));
    }).catch(error => {
      console.log(error);
    })
  }, [])

  useEffect(() => {
    const countryId = countryList.find(e=>e.value===formik.values.country)?.id;
    if(countryId) {
      http.get(`api/static/getAllState/${countryId}`).then(response => {
        setStateList(response.map((e) => ({ label: e.name, value: e.name, id: e.id })));
      }).catch(error => {
        console.log(error);
      })
    } else {
      setStateList([]);
    }
  }, [formik.values.country, countryList])

  useEffect(() => {
    const countryId = countryList.find(e=>e.value===formik.values.country)?.id;
    const stateId = stateList.find(e=>e.value===formik.values.state)?.id;
    if(countryId && stateId) {
      http.get(`api/static/getAllCity/${countryId}/${stateId}`).then(response => {
        setCityList(response.map((e) => ({ label: e.name, value: e.name })));
      }).catch(error => {
        console.log(error);
      })
    } else {
      setCityList([]);
    }
  }, [formik.values.state, formik.values.country, stateList])

  const imageUpload = (e) => {
    // console.log(e);
    let fileToUpload = e.target.files[0];

    var fileReader = new FileReader();
    function readFile(file) {
      fileReader.onload = function (e) {
        // console.log((fileReader.result).split(',')[1])
        if(fileReader.result.length>(275<<10)){
          Jimp.read(fileReader.result)
          .then(image => {
            image.resize(200, Jimp.AUTO);
            image.quality(80);
            image.getBase64Async(Jimp.MIME_JPEG).then(data => {
              // console.log(data.split(',')[1])
              formik.setFieldValue("image", data.split(',')[1])
            })
          })
          .catch(err => {
            console.log(err)
          });
        }else{
        // console.log((fileReader.result).split(',')[1])
        formik.setFieldValue("image", (fileReader.result).split(',')[1])
        }
      }
      fileReader.readAsDataURL(file);
    }
    if(fileToUpload){
      readFile(fileToUpload);
    }
  }

  return (
    <section>
      <Loader open={isLoading} />
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="profile_img">
                <div className="aos-init aos-animate w-100">
                  <img className="img-center" src={toImage(formik.values.image) || dummyImg} alt="Avatar"
                    title="Change the avatar" />
                </div>
              </div>
              <div className="w-100 mt-1">
                <input style={{display: "none"}} id="upload-button" type="file" accept="image/*" onChange={imageUpload}/>
                <label htmlFor="upload-button" className="w-100">
                  {/* <Button variant="contained" fullWidth color="primary" component="span">
                    
                  </Button> */}
                  <div className="btn-one pointer" style={{display:"flex", justifyContent:"center"}}>Change Picture</div>
                </label>
              </div>
              <div className="form-group">
                <input
                  name="name"
                  id="name"
                  placeholder="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name &&
                  <div className="text-error">{formik.errors.name}</div>}
              </div>

              <div>
                <Select
                  name="country"
                  value={countryList.find(
                    (e) => e.value === formik.values.country
                  )}
                  onChange={(e) =>
                    formik.setFieldValue("country", e.value)
                  }
                  options={countryList}
                  placeholder="Select Country"
                />
                {formik.touched.country && formik.errors.country &&
                  <div className="text-error">{formik.errors.country}</div>}
              </div>

              <div>
                <Select
                  name="state"
                  value={stateList.find(
                    (e) => e.value === formik.values.state
                  )}
                  onChange={(e) =>
                    formik.setFieldValue("state", e.value)
                  }
                  options={stateList}
                  placeholder="Select State"
                />
                {formik.touched.state && formik.errors.state &&
                  <div className="text-error">{formik.errors.state}</div>}
              </div>

              <div>
                <Select
                  name="city"
                  value={cityList.find(
                    (e) => e.value === formik.values.city
                  )}
                  onChange={(e) =>
                    formik.setFieldValue("city", e.value)
                  }
                  options={cityList}
                  placeholder="Select City"
                />
                {formik.touched.city && formik.errors.city &&
                  <div className="text-error">{formik.errors.city}</div>}
              </div>

              <div className="my-4">
                <h6>Company Name</h6>
                <input
                  name="companyName"
                  id="companyName"
                  placeholder="Company Name"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.companyName && formik.errors.companyName &&
                  <div className="text-error">{formik.errors.companyName}</div>}

              </div>

              <div>
                <h6>Job Role</h6>
                <Select
                  name="jobRole"
                  value={jobRoleList.find(e=>e.value === formik.values.jobRole)}
                  onChange={(e) => formik.setFieldValue("jobRole", e.value)}
                  options={jobRoleList}
                  placeholder="Select Job Role"
                />
                {formik.touched.jobRole && formik.errors.jobRole &&
                  <div className="text-error">{formik.errors.jobRole}</div>}
              </div>

              <h5 className="mt-4">Contact Details</h5>
              <ul className="list-unstyled user_data">
                <li>
                  <div>
                    <h6 className="d-inline mr-2">Email</h6>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["email"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <p>{profileData.email}</p>
                </li>
                <li className="mb-4">
                  <div>
                    <h6 className="d-inline mr-2">Phone</h6>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["phone"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <div className="form-group mb-2">                 
                    <PhoneInput
                      name="contactNo"
                      id="contactNo"
                      placeholder="Contact No"
                      value={formik.values.contactNo}
                      onChange={e => {formik.setFieldValue("contactNo", e)}}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.contactNo && formik.errors.contactNo &&
                      <div className="text-error">{formik.errors.contactNo}</div>}
                  </div>
                </li>
                <li className="mb-4">
                  <div>
                    <h6 className="d-inline mr-2">LinkedIn</h6>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["linkedin"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      name="linkedinUrl"
                      id="linkedinUrl"
                      placeholder="linkedin.com/..."
                      value={formik.values.linkedinUrl}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.linkedinUrl && formik.errors.linkedinUrl &&
                      <div className="text-error">{formik.errors.linkedinUrl}</div>}
                  </div>
                </li>
                <li>
                  <div>
                    <h6 className="d-inline mr-2">Meeting URL</h6>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["meetingWebUrl"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      name="meetingWebUrl"
                      id="meetingWebUrl"
                      placeholder="Meeting Web Url"
                      value={formik.values.meetingWebUrl}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.meetingWebUrl && formik.errors.meetingWebUrl &&
                      <div className="text-error">{formik.errors.meetingWebUrl}</div>}
                  </div>
                </li>

              </ul>

            </div>
            <div className="col-md-9 col-sm-9">

              <div className="profile_title mb-5">
                <div>
                  <h5>About</h5>
                  <div className="form-group">
                    <textarea
                      name="about"
                      id="about"
                      placeholder="About"
                      value={formik.values.about}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      rows="4"
                    />
                    {formik.touched.about && formik.errors.about &&
                      <div className="text-error">{formik.errors.about}</div>}
                  </div>
                </div>
              </div>

              <div className=" mb-5">
                <div>
                  <h5>Video Introduction</h5>
                  <div className="form-group">
                    <input
                      name="videoLinkUrl"
                      id="videoLinkUrl"
                      placeholder="youtube.com/... or vimeo.com/..."
                      value={formik.values.videoLinkUrl}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.videoLinkUrl && formik.errors.videoLinkUrl &&
                      <div className="text-error">{formik.errors.videoLinkUrl}</div>}
                  </div>
                </div>
              </div>

              <div className="row skills-content">
                <div className="col-md-6 col-sm-12 mb-5">
                  <div>
                    <h5 className="d-inline mr-2">Skills</h5>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["expertise"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <Select
                    name="skills"
                    value={skillList.filter(option => formik.values.skills.indexOf(option.value) >= 0)}
                    onChange={(e) => formik.setFieldValue("skills", e.map(k => k.value))}
                    isMulti
                    options={skillList}
                    placeholder="Select Skill"
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {formik.touched.skills && formik.errors.skills &&
                    <div className="text-error">{formik.errors.skills}</div>}
                </div>

                <div className="col-md-6 col-sm-12 mb-5">
                  <div>
                    <h5 className="d-inline mr-2">Industry Experience</h5>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["industryExperience"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <Select
                    name="industryExperience"
                    value={indExpList.filter(option => formik.values.industryExperience.indexOf(option.value) >= 0)}
                    onChange={(e) => formik.setFieldValue("industryExperience", e.map(k => k.value))}
                    isMulti
                    options={indExpList}
                    placeholder="Select Industry Experience"
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {formik.touched.industryExperience && formik.errors.industryExperience &&
                    <div className="text-error">{formik.errors.industryExperience}</div>}
                </div>

              </div>

              <div className="row skills-content">
                <div className="col-md-6 col-sm-12 mb-5">
                  <div>
                    <h5 className="d-inline mr-2">Language</h5>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["languages"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <Select
                    name="language"
                    value={languageList.filter(option => formik.values.language.indexOf(option.value) >= 0)}
                    onChange={(e) => formik.setFieldValue("language", e.map(k => k.value))}
                    isMulti
                    options={languageList}
                    placeholder="Select Language"
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {formik.touched.language && formik.errors.language &&
                    <div className="text-error">{formik.errors.language}</div>}
                </div>

                <div className="col-md-6 col-sm-12 mb-5">
                  <div>
                    <h5 className="d-inline mr-2">Method Of Contact</h5>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["methodOfContact"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <Select
                    name="methodOfContact"
                    value={mocList.filter(option => formik.values.methodOfContact.indexOf(option.value) >= 0)}
                    onChange={(e) => formik.setFieldValue("methodOfContact", e.map(k => k.value))}
                    isMulti
                    options={mocList}
                    placeholder="Select Method Of Contact"
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {formik.touched.methodOfContact && formik.errors.methodOfContact &&
                    <div className="text-error">{formik.errors.methodOfContact}</div>}
                </div>

              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 mb-5">
                  <div>
                    <h5 className="d-inline mr-2">Member Type</h5>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["memberType"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <Select
                    name="memberType"
                    value={memberTypeList.find(e=>e.value === formik.values.memberType)}
                    onChange={(e) => formik.setFieldValue("memberType", e.value)}
                    options={memberTypeList}
                    placeholder="Select Member Type"
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {formik.touched.memberType && formik.errors.memberType &&
                    <div className="text-error">{formik.errors.memberType}</div>}
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <div>
                    <h5 className="d-inline mr-2">Organization Type</h5>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["orgType"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <Select
                    name="orgType"
                    value={orgTypeList.find(e=>e.value === formik.values.orgType)}
                    onChange={(e) => formik.setFieldValue("orgType", e.value)}
                    options={orgTypeList}
                    placeholder="Select Organization Type"
                  />
                  {formik.touched.orgType && formik.errors.orgType &&
                    <div className="text-error">{formik.errors.orgType}</div>}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 mb-5">
                  <div>
                    <h5 className="d-inline mr-2">Availability Timeframe</h5>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["availabilityTimeframe"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <Select
                    name="availabilityTimeframe"
                    value={timeframeList.find(e=>e.value === formik.values.availabilityTimeframe)}
                    onChange={(e) => formik.setFieldValue("availabilityTimeframe", e.value)}
                    options={timeframeList}
                    placeholder="Select Availability Timeframe"
                  />
                  {formik.touched.availabilityTimeframe && formik.errors.availabilityTimeframe &&
                    <div className="text-error">{formik.errors.availabilityTimeframe}</div>}
                </div>
                <div className="col-md-6 col-sm-12 mb-5">
                  <div>
                    <h5 className="d-inline mr-2">Company Size</h5>
                    <span data-toggle="tooltip" data-placement="top" title={helptext["companySize"]}>
                      <i className="fas fa-info-circle"></i>
                    </span>
                  </div>
                  <Select
                    name="companySize"
                    value={companySizeList.find(e=>e.value === formik.values.companySize)}
                    onChange={(e) => formik.setFieldValue("companySize", e.value)}
                    options={companySizeList}
                    placeholder="Select Company Size"
                  />
                  {formik.touched.companySize && formik.errors.companySize &&
                    <div className="text-error">{formik.errors.companySize}</div>}
                </div>
              </div>

            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="form-group form-button float-right">
                <button style={{ backgroundColor: "#183359" }} onClick={viewProfilePage} type="button" className="form-submit">Back</button>
                <button disabled={formik.isSubmitting} type="submit" className="form-submit ml-3">Update</button>
              </div>
            </div>
          </div>

        </div>

      </form>

    </section>
  );
};

export default EditProfile;