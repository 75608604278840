import { useState } from "react";
import "./login.css";
// import "./login.scss";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useFormik } from "formik";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Swal from 'sweetalert2';
import http from "../../helper/api";
import Loader from "../common/template/Loader";
import signinImg from "../../assets/img/new/login_page.png";

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1.25),
    },
  },
  margin: {
    margin: theme.spacing(1.25),
    marginTop: theme.spacing(2),
  }
}));

const initialValues = {
  userName: "",
  password: "",
  showPassword: false,
};

const forgotInitialValues = {
  email: ""
};

const wrapper = {
  minHeight: "90vh",
  paddingTop: "15vh",
  backgroundColor: "#f8f8f8"
};

const Login = ({history}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [isForgot, setIsForgot] = useState(false);

  const loginUser = (values, {resetForm}) => {
    const {showPassword, ...temp} = values
    setIsLoading(true);
    http.post('api/signin', temp)
    .then(response => {
      resetForm(initialValues);
      // history.push('/home');
      setIsLoading(false);
      window.location.replace('/home');
    })
    .catch(error => {
      Swal.fire({
        title: 'Error',
        text: error.response?.data.message || "Server Error",
        icon: 'error',
      })
      setIsLoading(false);
    })
  }

  const forgotPassword = (values, {resetForm}) => {
    setIsLoading(true);
    http.post('public/api/forgotPassword', values)
    .then(response => {
      resetForm(forgotInitialValues);
      setIsLoading(false);
      Swal.fire({
        title: 'Success',
        text: response.message,
        icon: 'success',
      })
    })
    .catch(error => {
      Swal.fire({
        title: 'Error',
        text: error.response?.data.message || "Server Error",
        icon: 'error',
      })
      setIsLoading(false);
    })
  }

  const loginForm = useFormik({
    initialValues,
    onSubmit: loginUser,
    validate: values => {
      let errors = {};
      if(!values.userName){
        errors.userName = "Required"
      }
      if(!values.password){
        errors.password = "Required"
      }
      return errors;
    }
  });

  const forgotForm = useFormik({
    initialValues:forgotInitialValues,
    onSubmit: forgotPassword,
    validate: values => {
      let errors = {};
      if(!values.email){
        errors.email = "Required"
      }
      return errors;
    }
  });

  const handleClickShowPassword = () => {
    loginForm.setFieldValue('showPassword', !loginForm.values.showPassword)
  };

  return (
    <section style={wrapper}>
      <Loader open={isLoading} />
      <div className="mycontainer">
        <div className="signin-content">
          <div className="signin-image">
            <figure>
              <img src={signinImg} alt="sign in" />
            </figure>
          </div>
          {isForgot
          ? <div className="signin-form">
            <h2 className="form-title">Forgot Password</h2>
            <form onSubmit={forgotForm.handleSubmit} className={classes.root} autoComplete="off">
              <div>
                <TextField
                  name="email"
                  onChange={forgotForm.handleChange}
                  onBlur={forgotForm.handleBlur}
                  value={forgotForm.values.email}
                  fullWidth
                  label="Your Email"
                />
                {forgotForm.touched.email && forgotForm.errors.email && <div className="text-error">{forgotForm.errors.email}</div>}
              </div>
              <Button type="submit" className={classes.margin} size="large" variant="contained" color="primary">
                Reset Password
              </Button>
              <div onClick={()=>setIsForgot(false)} className="signup-link" style={{textAlign:"left"}}>
                Login to your account
              </div>
            </form>
          </div>
          : <div className="signin-form">
            <h2 className="form-title">Sign In</h2>
            <form onSubmit={loginForm.handleSubmit} className={classes.root} autoComplete="off">
              <div>
                <TextField
                  name="userName"
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  value={loginForm.values.userName}
                  fullWidth
                  label="Your Email"
                />
                {loginForm.touched.userName && loginForm.errors.userName && <div className="text-error">{loginForm.errors.userName}</div>}
              </div>
              <div>
                <TextField
                  name="password"
                  type={loginForm.values.showPassword ? 'text' : 'password'}
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  value={loginForm.values.password}
                  fullWidth
                  label="Password"
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {loginForm.values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }}
                />
                {loginForm.touched.password && loginForm.errors.password && <div className="text-error">{loginForm.errors.password}</div>}
              </div>
              <button type="submit" className="btn-one">
                Login
              </button>
              <div onClick={()=>setIsForgot(true)} className="signup-link" style={{textAlign:"left"}}>
                Forgot Password
              </div>
            <div onClick={()=>history.push("signup")} className="signup-link">
              Create an account
            </div>
            </form>
          </div>}
        </div>
      </div>
    </section>
  );
}

export default Login;