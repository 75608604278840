import { Suspense, useContext, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./component/login/Login";
import Profile from "./component/profile/Profile";
import Signup from "./component/signup/Signup";
import Home from "./component/home/Home";
// import NoMatch from "./component/common/noMatch/NoMatch";
import { UserContext } from "./UserContext";
import Unauthorized from "./component/common/noMatch/Unauthorized";

// const PubnubChat = lazy(() => import("./component/pubnub/PubnubChat"));
const ChatWrapper = lazy(() => import("./component/pubnub/ChatWrapper"));

const Discover = lazy(() => import("./component/discover/Discover"));
const Connection = lazy(() => import("./component/connection/Connection"));
const ResetPassword = lazy(() =>
  import("./component/reset-password/ResetPassword")
);
const MeetingFeedback = lazy(() =>
  import("./component/meetingFeedback/meetingFeedback")
);
const VerifyEmail = lazy(() => import("./component/verify-email/VerifyEmail"));
const PaymentSuccess = lazy(() =>
  import("./component/payment-success/PaymentSuccess")
);
const PaymentError = lazy(() =>
  import("./component/payment-error/PaymentError")
);
const Notification = lazy(() =>
  import("./component/notification/Notification")
);
const Aboutus = lazy(() => import("./component/aboutus/Aboutus"));

const Admin = lazy(() => import("./component/admin/Admin"));
const UserPanel = lazy(() => import("./component/admin/UserPanel"));
const Messages = lazy(() => import("./component/admin/Messages"));
const EditHomeDetails = lazy(() => import("./component/admin/EditHomeDetails"));
const EditAttributes = lazy(() => import("./component/admin/EditAttributes"));
const EditMentorCategory = lazy(() =>
  import("./component/admin/EditMentorCategory")
);
const MeetingList = lazy(() => import("./component/admin/MeetingList"));

const roles = {
  admin: [
    "/profile",
    "/admin",
    "/admin/users",
    "/admin/feedback",
    "/admin/editContent",
    "/admin/editAttributes",
    "/admin/editCategory",
    "/admin/meetingFeedback",
  ],
  mentor: ["/profile", "/discover", "/connections", "/payment-success", "/payment-error", "/notifications", "/chat"],
  mentee: ["/profile", "/discover", "/connections", "/payment-success", "/payment-error", "/notifications", "/chat"],
};

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <NotLoggedinRoute exact path="/signin" component={Login} />
      <NotLoggedinRoute exact path="/signup" component={Signup} />
      <NotLoggedinRoute
        path="/resetPassword/:token"
        component={ResetPassword}
      />
      <NotLoggedinRoute path="/verifyEmail/:token" component={VerifyEmail} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/about">
        <Suspense fallback={<div>Loading...</div>}>
          <Aboutus />
        </Suspense>
      </Route>
      <Route path="/error">
        <Redirect to="/home" />{" "}
      </Route>

      <PrivateRoute exact path="/profile" component={Profile} />
      <PrivateRoute exact path="/chat" component={ChatWrapper} />
      <PrivateRoute exact path="/notifications" component={Notification} />
      <PrivateRoute exact path="/payment-success" component={PaymentSuccess} />
      <PrivateRoute exact path="/payment-error" component={PaymentError} />
      {/* Mentee / Mentor Routes */}
      <PrivateRoute exact path="/discover" component={Discover} />
      <PrivateRoute exact path="/connections" component={Connection} />
      <PrivateRoute
        exact
        path="/feedback/:meetingId"
        component={MeetingFeedback}
      />
      {/* Admin Routes */}
      <PrivateRoute exact path="/admin" component={Admin} />
      <PrivateRoute exact path="/admin/users" component={UserPanel} />
      <PrivateRoute exact path="/admin/feedback" component={Messages} />
      <PrivateRoute
        exact
        path="/admin/meetingFeedback"
        component={MeetingList}
      />
      <PrivateRoute
        exact
        path="/admin/editContent"
        component={EditHomeDetails}
      />
      <PrivateRoute
        exact
        path="/admin/editAttributes"
        component={EditAttributes}
      />
      <PrivateRoute
        exact
        path="/admin/editCategory"
        component={EditMentorCategory}
      />
      <PrivateRoute exact path="/userprofile" component={Profile} />

      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

function PrivateRoute({ component: Component, ...rest }) {
  const userAuth = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props;
        // if user is not from following member types:
        if (!["mentee", "mentor", "admin"].includes(userAuth.memberType)) {
          return (
            <Redirect to={{ pathname: "/error", state: { from: location } }} />
          );
        }

        if (
          !roles[userAuth.memberType].includes(location.pathname) &&
          !location.pathname.startsWith("/feedback")
        ) {
          return <Redirect to={{ pathname: "/error" }} />;
        } else if (!userAuth.verified && location.pathname !== "/profile") {
          return <Unauthorized />;
        }

        // authorised so return component
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
          </Suspense>
        );
      }}
    />
  );
}

function NotLoggedinRoute({ component: Component, ...rest }) {
  const userAuth = useContext(UserContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props;
        if (userAuth.memberType !== undefined) {
          return (
            <Redirect to={{ pathname: "/home", state: { from: location } }} />
          );
        }
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Component {...props} />
          </Suspense>
        );
      }}
    />
  );
}

export default Routes;
