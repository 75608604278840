import axios from "axios";

const instance = axios.create({
  withCredentials: true,
  credentials: "include",
});

instance.interceptors.request.use(
  (config) => {
    let baseIP = `${process.env.REACT_APP_API_ENDPOINT}/`;
    // let token = sessionStorage.getItem('token')
    // if (token) {
    //   config.headers['Authorization'] = 'Bearer ' + token;
    // }
    config.url = baseIP + config.url;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
