
import { Link } from 'react-router-dom';

const Unauthorized = ({location}) => {

//   let { from } = location.state || { from: { pathname: "/" } };

  return (
    <div className="container my-5">
      <h3>Please fill out your profile details so our team can activate your account. Welcome to the Mentor On Demand community!</h3>
      <div>Go to <Link to="/home">Home</Link></div>
    </div>
  )
}

export default Unauthorized;