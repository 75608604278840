import { useEffect, useState } from "react";
import http from "../../../helper/api";

const About = () => {

  const [workflowList, setWorkflowList] = useState([]);

  useEffect(() => {
    http.get('public/api/getDataForHomePage/workflow')
      .then(response => {
        setWorkflowList(response.workflow);
      })
      .catch(error => {
        console.log(error);
      })
  }, []);


  return (
    <div style={{ position: "relative" }}>
      <section id="howItWorks" className="about" style={{padding:"30px 0 60px 0"}}>
        <div className="container" data-aos="fade-up">

          <div className="row no-gutters">
            <div className="content col-xl-5 d-flex align-items-stretch">
              <div className="content">
                <h3>How it works in 4 simple steps...    </h3>
                <p>

                </p>
                <a href="/signup" className="about-btn"><span>Register</span>
                  <i className="fa fa-angle-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
            <div className="col-xl-7 d-flex align-items-stretch">
              <div className="icon-boxes d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                  <i className="fas fa-user-check"></i>
                    <h4>{workflowList[0]?.title}</h4>
                    <p>{workflowList[0]?.content}</p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                    <i className="fas fa-pen-square"></i>
                    <h4>{workflowList[1]?.title}</h4>
                    <p>{workflowList[1]?.content}</p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                    <i className="fa fa-search" aria-hidden="true"></i>
                    <h4>{workflowList[2]?.title}</h4>
                    <p>{workflowList[2]?.content}</p>
                  </div>
                  <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                    <i className="fas fa-project-diagram"></i>
                    <h4>{workflowList[3]?.title}</h4>
                    <p>{workflowList[3]?.content}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;