import { useEffect, useState } from "react";
import http from "../../../helper/api";

const Faq = () => {
  const [faqAList, setFaqAList] = useState([]);
  const [faqBList, setFaqBList] = useState([]);

  useEffect(() => {
    http
      .get("public/api/getDataForHomePage/faq")
      .then((response) => {
        // console.log("Home:",response)
        setFaqAList(response.faq_mentee);
        setFaqBList(response.faq_mentor);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section id="faq" className="faq" style={{ padding: "60px 0" }}>
      <div className="container" data-aos="fade-up">

        <div className="row">
          <div className="col-md-12">
            <div className="section-title">
              <h2>Frequently Asked Questions</h2>
              <p></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-6">
            <div className="section-title">
              <h2 className="fs-l">For Mentee</h2>
              <p></p>
            </div>

            <ul id="faq-a" className="faq-list" data-aos="fade-up">
              {faqAList &&
                faqAList.map((faq, index) => (
                  <li key={index}>
                    <a
                      data-toggle="collapse"
                      className="collapsed"
                      href={`#faq-a-${index}`}
                    >
                      {faq.question}
                      <i className="fa fa-angle-down icon-show"></i>
                      <i className="fa fa-times icon-close"></i>
                    </a>
                    <div
                      id={`faq-a-${index}`}
                      className="collapse"
                      data-parent="#faq-a"
                    >
                      <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          <div className="col-md-12 col-lg-6">
            <div className="section-title">
              <h2 className="fs-l">For Mentor</h2>
              <p></p>
            </div>

            <ul id="faq-b" className="faq-list" data-aos="fade-up">
              {faqBList &&
                faqBList.map((faq, index) => (
                  <li key={index}>
                    <a
                      data-toggle="collapse"
                      className="collapsed"
                      href={`#faq-b-${index}`}
                    >
                      {faq.question}
                      <i className="fa fa-angle-down icon-show"></i>
                      <i className="fa fa-times icon-close"></i>
                    </a>
                    <div
                      id={`faq-b-${index}`}
                      className="collapse"
                      data-parent="#faq-b"
                    >
                      <p dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
