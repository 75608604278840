import "./footer.css";

const Footer = () => {
  return (
    <footer id="footer" className="mt-3">
      <div className="container d-md-flex py-4">
        <div className="mr-md-auto text-center text-md-left">
          <div className="copyright">
            &copy; Copyright <strong><span>Mentor On Demand</span></strong>. All Rights Reserved
        </div>
          <div className="credits">
            An initiative of accelerator group in partnership with <a href="https://www.awefoundation.org/">AWE Foundation</a>
          </div>
        </div>
        <div className="social-links text-center text-md-right pt-3 pt-md-0">
          <a href="https://twitter.com/awefoundation" className="twitter"><i className="fab fa-twitter"></i></a>
          <a href="https://www.facebook.com/AWEFoundation.Org" className="facebook"><i className="fab fa-facebook-f"></i></a>
          {/* <a href="/" className="instagram"><i className="fab fa-instagram"></i></a>
          <a href="/" className="google-plus"><i className="fab fa-skype"></i></a> */}
          <a href="https://www.linkedin.com/company/awe-achieving-women-equity-foundation/about/?viewAsMember=true" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;