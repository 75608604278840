import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from'jquery';
import Popper from'popper.js';
import'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router } from 'react-router-dom';
import Navbar from './component/common/navbar/Navbar';
import Routes from './Routes';
import "aos/dist/aos.css";
import { init } from "aos/dist/aos";
import http from "./helper/api";
import Footer from "./component/common/footer/Footer";
import Header from "./component/common/header/Header";
import { UserContext } from './UserContext';
import './App.scss';
import "./material.scss";

function App() {
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    init({
      duration: 1000,
      once: true
    });
  },[]);

  useEffect(()=>{
    setIsLoading(true);
    http.get("api/verifytoken").then(res=>{
      setUserData(res);
      setIsLoading(false);
    }).catch((_)=>{
      setUserData({});
      setIsLoading(false);
    });
  },[])

  return (
    <Router>
      <UserContext.Provider value={userData}>
        <Navbar />
        <Header />
        {!isLoading && <Routes />}

        <Footer />
      </UserContext.Provider>
    </Router>

  );
}
export default App;
