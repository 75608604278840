import { useCallback, useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import "./navbar.scss";

import logo from "../../../assets/img/logo.png";
import { UserContext } from "../../../UserContext";

import http from "../../../helper/api";

const Navbar = ({ history }) => {

  const [notificationList, setNotificationList] = useState([]);
  const userAuth = useContext(UserContext);
  const location = useLocation();
  const [headerClass, setHeaderClass] = useState("navbar navbar-expand-md navbar-light fixed-top");

  const onScroll = useCallback(() => {
    if (window.scrollY > 10) {
      setHeaderClass("navbar navbar-expand-md navbar-light fixed-top header-scrolled");
    } else {
      setHeaderClass("navbar navbar-expand-md navbar-light fixed-top");
    }
  }, []);

  const getNotifications = () => {
    http.get("api/getNotification").then(response => {
      setNotificationList(response);
    })
    .catch(error => {
      console.log(error);
    })
  };

  useEffect(() => {
    if (userAuth.memberType) {
        // getNotifications();
        const timer1 = setInterval(getNotifications, 60 * 1000);
        return () => {
          clearInterval(timer1);
        }
    }
  }, [userAuth]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => { window.removeEventListener("scroll", onScroll); }
  }, []);

  const markReadNotification = () => {
    if (notificationList.length>0) {
      const idArr = notificationList.map(e=>e._id)
      http.post("api/markReadNotification", {idArr})
      .then(_=>{})
      .catch(_=>{})
    }
  };

  const logout = useCallback(() => {
    http.post("api/logout", {}).then(response => {
      window.location = "/home";
    })
    .catch(error => {
      console.log(error);
    })
  }, []);

  return (
    <nav id="nav1" className={headerClass}>
      <Link to="/" className="logo mr-auto ml-5"><img src={logo} alt="" /></Link>
      <button className="navbar-toggler mr-2" style={{ border: "none" }} type="button" data-toggle="collapse" data-target="#navbarToggler1" aria-controls="navbarToggler1" aria-expanded="false" aria-label="Toggle navigation">
        <i className="fa fa-bars" aria-hidden="true"></i>
      </button>
      <div className="collapse navbar-collapse mr-5" id="navbarToggler1">
        <ul className="navbar-nav ml-auto mt-2 mt-lg-0">

          <li className={location.pathname === "/home" ? "active nav-item" : "nav-item"}>
            <Link className="nav-link" to="/home">Home</Link>
          </li>

          <li className={location.pathname === "/about" ? "active nav-item" : "nav-item"}>
            <Link className="nav-link" to="/about">About Us</Link>
          </li>

          {/* <li><a href="/">Resources</a></li> */}
          {/* {location.pathname === "/home"
            ? (<li><a className="nav-link" href="#contact">Contact</a></li>)
            : null} */}
          {userAuth && ["mentee", "mentor"].includes(userAuth.memberType)
            ? (
              <>
                <li className={location.pathname === "/discover" ? "active nav-item" : "nav-item"}>
                  <Link className="nav-link" to="/discover">Discover</Link>
                </li>
                <li className={location.pathname === "/connections" ? "active nav-item" : "nav-item"}>
                  <Link className="nav-link" to="/connections">Connections</Link>
                </li>
              </>
            )
            : null}
          {userAuth && userAuth.memberType === "admin"
            ? (
              <li className={location.pathname === "/admin" ? "active nav-item" : "nav-item"}>
                <Link className="nav-link" to="/admin">Admin</Link>
              </li>
            )
            : null}
          <li className="nav-item px-0">
            {userAuth && userAuth.memberType ? null
              : (<button type="button" onClick={() => history.push('/signin')} className="get-started-btn mr-5">
                Login
              </button>)}
          </li>
          {userAuth.email && (
            <>
              <li className="nav-item">
                <button className="nav-link btn-text" onClick={markReadNotification} id="navbarDropdownbell" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <Badge badgeContent={notificationList.length} color="primary">
                  <i style={{marginTop:"4px"}} className="fa fa-bell"></i>
                </Badge>
                </button>
                <div className="dropdown-menu pb-0" aria-labelledby="navbarDropdownbell">
                  <ul className="notification-list">
                    { notificationList.length > 0
                      ? notificationList.map((e)=> <li key={e._id}>{e.message}</li>)
                      : <li>No New Notification</li>
                    }
                    <li><Link style={{color:"royalblue"}} to="/notifications">View All Notification</Link></li>
                  </ul>
                </div>
              </li>
              <li className="nav-item dropdown" style={{minWidth: "105px"}}>
                <button className="nav-link dropdown-toggle btn-text" id="navbarDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {userAuth.name}
                </button>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item" to="/profile">Profile</Link>
                  <button type="button" onClick={logout} className="dropdown-item pointer">Sign Out</button>
                </div>
              </li>
            </>
          )}
        </ul>
      </div>
    </nav>
  );
}

export default withRouter(Navbar);